import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd/lib/grid";
import Menu from "antd/lib/menu";
import Layout from "antd/lib/layout";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons/lib/icons";

import { logout } from "../store/auth.reducer";
import { RootState } from "../store";
import Navigation from "./Navigation";

import { ReactComponent as WorkIcon } from "../assets/work.svg";
import { ReactComponent as ScanIcon } from "../assets/scan.svg";
import { ReactComponent as VoiceIcon } from "../assets/voice.svg";
import { ReactComponent as FolderIcon } from "../assets/folder.svg";
import logo from "../assets/logo.png";

const StyledHeader = styled(Layout.Header)`
  background: transparent;
  height: 100px;
  padding: 0;
  line-height: normal;
`;

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { auth, vacancies } = useSelector((state: RootState) => state);

  const handleLogout = () => {
    localStorage.removeItem("cvilla_user_token");
    dispatch(logout());
    history.push("/");
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Выйти из аккаунта
      </Menu.Item>
    </Menu>
  );

  const vacancyProcessingCount = vacancies.filter((v) => v.is_available === false).length;

  return (
    <StyledHeader>
      <Row align="middle" style={{ height: 100 }}>
        <Col span={11} offset={1}>
          <img src={logo} alt="" />
        </Col>
        <Col span={11} style={{ textAlign: "right" }}>
          <Navigation background="#f6faff" activeColor="#5061DC">
            <Navigation.Item
              to="/vacancy"
              key={1}
              icon={<FolderIcon />}
              isActive={pathname === "/vacancy"}
              isHidden={!["admin"].includes(auth.role)}
            >
              Разметка вакансий
            </Navigation.Item>
            <Navigation.Item
              to="/vacancies"
              key={2}
              icon={<WorkIcon />}
              isActive={pathname === "/vacancies" || pathname.startsWith("/vacancy/")}
              isHidden={!["admin"].includes(auth.role)}
            >
              Вакансии
            </Navigation.Item>
            <Navigation.Item
              to="/resume"
              key={3}
              icon={<ScanIcon />}
              isActive={pathname === "/resume"}
              isHidden={!["admin"].includes(auth.role)}
            >
              Маппинг резюме
            </Navigation.Item>
            <Navigation.Item
              to="/audio"
              key={4}
              icon={<VoiceIcon />}
              isActive={pathname.startsWith("/audio")}
              isHidden={vacancyProcessingCount > 0 || !["admin"].includes(auth.role)}
            >
              Аудиоинтервью
            </Navigation.Item>
            <Navigation.User menu={dropdownMenu} {...auth}>
              <UserOutlined />
            </Navigation.User>
          </Navigation>
        </Col>
      </Row>
    </StyledHeader>
  );
};

export default Header;
