import styled from "styled-components";
import { Space, Typography, Button, Badge, Layout } from "antd";

export const PositionList = styled(Layout)`
  flex-direction: column;
  gap: 0.5rem;
`;

export const PositionButton = styled(Button)`
  display: flex;
  flex-direction: column;
  height: 75px;
  width: 330px;
  border: 0;
  padding: 0.5rem;
  background: #f0f2f5;
  box-shadow: none;
  max-width: 100%;

  .ant-space,
  :focus,
  :hover {
    background: #f0f2f5;
  }
`;

export const PositionWrapper = styled(Space)`
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  max-width: 100%;
`;

export const PositionTitle = styled(Typography.Text)`
  max-width: 100%;
  font-size: 1rem;
  font-weight: 500;
`;

export const ToolsWrapper = styled(Layout)`
  background: #f0f2f5;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 0.5rem;
  max-width: 100%;
  max-height: 1.5rem;
  flex-basis: 0;
`;

export const Tool = styled(Badge)`
  .ant-badge-count {
    background: #a1a0a2;
    border-radius: 3px;
  }
`;

export const NoToolsText = styled(Typography.Text)`
  font-size: 0.8rem;
  font-weight: 400;
  color: #a1a0a2;
`;
