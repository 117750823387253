import { useState } from "react";

import {
  PositionWrapper,
  PositionTitle,
  ToolsWrapper,
  Tool,
  NoToolsText,
  PositionButton,
  PositionList,
} from "./styled/positions.styled";
import { ScrolledBlock, WhiteAroundBlock } from "../shared/block.styled";
import { Input } from "antd";

import { IPosition } from "../store/positions/positions.reducer";
import { selectPositions } from "../store/positions/positions.selector";
import { useTypedSelector } from "../store";

interface FunctionalPositionProps extends IPosition {
  handleSelectPosition: (e: string) => void;
}

const FunctionalPosition = ({ id, name, tools, handleSelectPosition }: FunctionalPositionProps) => {
  return (
    <PositionButton key={id} id={id} onClick={(e) => handleSelectPosition(e.currentTarget.id)}>
      <PositionWrapper>
        <PositionTitle>{name}</PositionTitle>
        <ToolsWrapper>
          {tools.length ? (
            tools.map((tool) => <Tool key={`${tool.id}-${name}`} count={tool.name} />)
          ) : (
            <NoToolsText>Инструменты не указаны</NoToolsText>
          )}
        </ToolsWrapper>
      </PositionWrapper>
    </PositionButton>
  );
};

interface FunctionalPositionsProps {
  handleSelectPosition: (id: string) => void;
  style?: React.CSSProperties;
}

export const FunctionalPositions = ({ handleSelectPosition }: FunctionalPositionsProps) => {
  const positions = useTypedSelector(selectPositions);
  const [searchValue, setSearchValue] = useState<string>("");

  const filteredArray = positions.filter((functionalPosition) =>
    functionalPosition.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const positionsArray = searchValue ? [...filteredArray] : [...positions];

  return (
    <WhiteAroundBlock
      style={{
        minWidth: "21.5%",
        maxHeight: "fit-content",
        padding: "1rem",
      }}
    >
      <Input.Search
        size="middle"
        placeholder="Добавить функциональную позицию"
        style={{ marginBottom: "1.3rem" }}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <ScrolledBlock height={550}>
        <PositionList>
          {positionsArray.map((functionalPosition) => {
            return (
              <FunctionalPosition
                key={functionalPosition.id}
                id={functionalPosition.id}
                name={functionalPosition.name}
                tools={functionalPosition.tools}
                handleSelectPosition={handleSelectPosition}
              />
            );
          })}
        </PositionList>
      </ScrolledBlock>
    </WhiteAroundBlock>
  );
};
