import axios from "axios";
import { IPosition } from "../store/positions/positions.reducer";

const request = axios.create({
  baseURL: "https://test.api.fps.2035.university",
});

const fetch = async (): Promise<IPosition[]> => {
  const response = await request(`/positions`);
  return response.data;
};

const update = async (id: string, fields: IPosition): Promise<IPosition> => {
  try {
    const response = await request.put(`/positions/${id}`, fields);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const remove = async (id: string) => {
  try {
    const response = await request.delete(`/positions/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

const FPositionsAPI = { fetch, update, remove };

export default FPositionsAPI;
