import { useDrop } from "react-dnd";
import styled from "styled-components";
import { typeColor } from "../../helpers/typeColor";
import { ScrolledBlock, WhiteAroundBlock } from "../../shared/block.styled";
import { InfoMessage } from "../../shared/text.styled";
import { ITool } from "../../store/positions/positions.reducer";
import NewEntityTag from "../NewEntityTag";
import { TransferButton, BoxTitle } from "./dnd.styled";

import { Layout } from "antd";

type TransferTargetProps = {
  values: ITool[];
  onDrop: (item: ITool) => void;
  onRemove: (id: string) => void;
  onReset?: () => void;
  onDoubleClick?: (id: string) => void;
  rowId: keyof ITool;
};

const DropBlock = styled(ScrolledBlock)<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? "#ccc" : "transparent")};
`;

const InfoBlock = styled(DropBlock)`
  display: flex;
  align-items: center;
  text-align: center;
`;

const TransferTarget: React.FC<TransferTargetProps> = ({
  values,
  onDrop,
  onReset,
  onRemove,
  onDoubleClick,
  rowId,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ["task", "instrument"],
      drop: onDrop,
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  const isActive = canDrop && isOver;

  if (values.length === 0) {
    return (
      <WhiteAroundBlock
        ref={drop}
        style={{
          maxWidth: "50%",
        }}
      >
        <BoxTitle level={5}>Используемые инструменты</BoxTitle>
        <InfoBlock height={500} isActive={isActive}>
          <InfoMessage>
            Перетащите нужные элементы сюда и нажатием выделите те из них, которые являются
            приоритетными для данной позиции (не больше 3). Вопросы по соответствующим им
            компетенциям будут заданы в ходе аудиоинтервью.
          </InfoMessage>
        </InfoBlock>
      </WhiteAroundBlock>
    );
  }

  return (
    <WhiteAroundBlock
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: "50%",
      }}
    >
      <BoxTitle level={5}>Используемые инструменты</BoxTitle>
      <Layout
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <DropBlock height={540} isActive={isActive} ref={drop}>
          {values.map((item) => (
            <NewEntityTag
              color={typeColor[item.type]}
              key={item[rowId]?.toString()}
              {...item}
              onRemove={onRemove}
              onDoubleClick={onDoubleClick}
            />
          ))}
        </DropBlock>
        {onReset && <TransferButton onClick={onReset}>Очистить все</TransferButton>}
      </Layout>
    </WhiteAroundBlock>
  );
};

export default TransferTarget;
