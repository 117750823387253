import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PositionsAPI from "../api/positions.api";

export interface ITool {
  id: string;
  origin_id: string | null;
  name: string;
  is_priority: boolean;
}

export interface IPsition {
  id: string;
  name: string;
  comment: string | null;
  origin_id: string | null;
  owner_id: string | null;
  tools: ITool[];
}

const initialState: IPsition[] = [];

export const fetchPositions = createAsyncThunk("positions/fetch", async () => {
  const positions = await PositionsAPI.fetch();
  return positions;
});

const positionsSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    setPositions(_, { payload }) {
      return payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPositions.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = positionsSlice;

export const { setPositions } = actions;

export default reducer;
