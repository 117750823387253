import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { Content } from "antd/lib/layout/layout";
import Layout from "antd/lib/layout";
import Spin from "antd/lib/spin";

import { RootState } from "./store";
import Header from "./components/Header";
import Footer from "./components/Footer";

import NotFound from "./pages/NotFound";
import NewVacancyMapping from "./pages/NewVacancyMapping";


import "./scss/index.scss";

function App() {
  const { isLoading } = useSelector((state: RootState) => state.app);

  return (
    <Spin tip="Загрузка данных..." spinning={isLoading}>
      <Layout hasSider={false}>
        <Router>
          <Header />
          <Content>
            <Switch>
              <Route path="/" exact component={NewVacancyMapping} />
              <Route component={NotFound} />
            </Switch>
          </Content>
          <Footer />
        </Router>
      </Layout>
    </Spin>
  );
}

export default App;
