import { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";

import { RootState, useTypedDispatch, useTypedSelector } from "../store";
import { fetchPositions, ITool, updatePosition } from "../store/positions/positions.reducer";
import { selectAllTools } from "store/positions/positions.selector";
import FPositionsAPI from "../api/fpositions.api";

import { FunctionalPositions } from "../components/FunctionalPositions";
import ContentWrapper from "../components/ContentWrapper";
import DnDContentWrapper from "../components/DnDContentWrapper";
import Dnd from "../components/dnd/Dnd";

import styled from "styled-components";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import { Folder } from "react-iconly";
import { Empty, Layout, Popconfirm, Input } from "antd";
import { EditFilled } from "@ant-design/icons";
import { WhiteAroundBlock } from "../shared/block.styled";

const FooterControl = styled.div`
  text-align: right;
  button {
    margin-left: 10px;
  }
`;

const HeaderControl = styled(Layout)`
  flex-direction: row;
  justify-content: space-between;
  background: #f7faff;
  padding: 0 !important;
  margin-bottom: 0.5rem;
  gap: 1rem;
  .ant-page-header {
    padding: 0 !important;
    margin: 0;
  }
  .ant-input-affix-wrapper {
    width: fit-content;
    border-radius: 12px;
    input {
      font-weight: 500;
    }
  }
`;

const MappingWrapper = styled(Layout)`
  width: 100%;

  background: #f7faff;
  // height: ;
  > .ant-space-item:nth-of-type(2) {
    width: 100%;
  }
`;

const EmptyMappingWrapper = styled(MappingWrapper)`
  flex-direction: row;
  // border: 2px solid green;
  max-height: 95%;
  > .ant-space-item:nth-of-type(2) {
    > :first-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
const { confirm } = Modal;

type RouteParams = {
  vacancy_id: string;
};

const VacancyMapping: React.FC<RouteComponentProps<RouteParams>> = ({ match, history }) => {
  const { vacancies, fpositions } = useTypedSelector((state: RootState) => state);
  const dispatch = useTypedDispatch();
  const { vacancy_id } = match.params;
  const [positionId, setPositionId] = useState("");
  const [values, setValues] = useState<ITool[]>([]);
  const vacancy = vacancies.find((v) => v.id === vacancy_id);
  const position = fpositions.filter((p) => p.id === positionId)[0];
  const tools = useTypedSelector(selectAllTools);
  const [positionName, setPositionName] = useState<string>("");

  // Добавляем tools позиции в список выбранных
  useEffect(() => {
    if (!position) return;
    setPositionName(position.name);
    position.tools.length > 0 ? setValues(position?.tools) : setValues([]);
  }, [position]);

  // Подгружаем позиции если их нет
  useEffect(() => {
    fpositions.length === 0 && dispatch(fetchPositions());
  }, [fpositions.length, dispatch]);

  //? DEBUG
  const handleSelectPosition = (id: string) => {
    setPositionId(id);
  };
  //? DEBUG

  // Добавляем дропнутый элемент в стейт
  const handleDrop = (item: ITool) => {
    const { id, type, name, origin_id, is_priority } = item;
    setValues((prev) => prev.concat({ id, type, name, origin_id, is_priority }));
  };

  // handler кнопки "Очистить всё"
  const handleReset = () => setValues([]);

  // Фильтруем элементы которые уже были выбраны
  const filteredTools = useMemo(() => {
    // Получаем список айдишников выбранных элементов
    const ids = values.map((item) => item.id);
    // Возвращаем список элементов без выбранных
    return tools.filter((item) => !ids.includes(item.id));
  }, [values, tools]);

  /**
   * Обновляем элементы вакансии
   */
  const handleSaveVacancy = async () => {
    try {
      const fPosition = await FPositionsAPI.update(positionId, {
        id: positionId,
        name: positionName,
        tools: values,
      });
      dispatch(updatePosition(fPosition));
      history.push(`/vacancies`);
    } catch (error: any) {
      console.log("error :>> ", error);
    }
  };

  const handleRemoveVacancy = async () => {
    try {
      const fPosition = await FPositionsAPI.remove(positionId);
      dispatch(updatePosition(fPosition));
      history.push(`/vacancies`);
    } catch (error: any) {
      console.log("error :>> ", error);
    }
  };

  // Удаляем выбранный элемент из списка
  const handleRemove = (id: string) => {
    // Проверяем есть ли в полном списке сущностей данный элемент
    const isIdExists = tools.some((item) => item.id === id);
    if (isIdExists) {
      // Если есть, просто удаляем из списка выбранных элементов
      setValues(values.filter((v) => v.id !== id));
    } else {
      // Если нет, то делаем текущий элемент выключенным
      setValues(
        values.map((item) => (item.id === id ? { ...item, is_disabled: !item.is_disabled } : item))
      );
    }
  };

  // Назначаем элемент приоритетным
  const handleSetPriority = (id: string) => {
    // Считаем количество приоритетных элементов
    const priorityCount = values.filter((v) => v.is_priority && v.id !== id).length;
    if (priorityCount < 3) {
      setValues(
        values.map((item) => (item.id === id ? { ...item, is_priority: !item.is_priority } : item))
      );
    } else {
      message.warn("Вы можете выбрать не более 3-х");
    }
  };

  /**
   * Обрабатываем клик по кнопке "Назад"
   */
  const handleBack = () =>
    confirm({
      title: "Указанное действие приведет к потере несохраненных данных. Всё равно продолжить?",
      onOk() {
        history.push("/vacancy");
      },
    });

  // Добавляем все элементы в список выбранных
  const handleAppend = (tools: ITool[]) => setValues((prev) => prev.concat(tools));

  if (!position) {
    return (
      <ContentWrapper
        icon={<Folder set="light" primaryColor="#98A4BC" size={20} />}
        onBack={() => null}
      >
        <Layout
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            maxHeight: "95%",
            gap: "1rem",
            background: "#f7faff",
          }}
        >
          <FunctionalPositions handleSelectPosition={handleSelectPosition} />
          <WhiteAroundBlock
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty description={"Выберите функциональную позицию"} />
          </WhiteAroundBlock>
        </Layout>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper icon={<Folder set="light" primaryColor="#98A4BC" size={20} />}>
      <Layout
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 3fr",
          maxHeight: "95%",
          gap: "1rem",
          background: "#f7faff",
        }}
      >
        <FunctionalPositions handleSelectPosition={handleSelectPosition} />
        <DnDContentWrapper
          icon={<Folder set="light" primaryColor="#98A4BC" size={20} />}
          onBack={() => null}
          headerContent={
            <HeaderControl>
              <Input
                value={positionName}
                suffix={<EditFilled />}
                onChange={(event) => setPositionName(event.target.value)}
                style={{ width: "20%" }}
              />
              <Layout
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  background: "#f7faff",
                }}
              >
                <Popconfirm
                  placement="topLeft"
                  title={"Вы уверены, что хотите удалить вакансию?"}
                  onConfirm={handleRemoveVacancy}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button
                    size="middle"
                    type="default"
                    style={{ fontSize: "0.9rem", fontWeight: 500 }}
                  >
                    Удалить
                  </Button>
                </Popconfirm>

                <Button
                  size="middle"
                  type="primary"
                  disabled={values.length === 0}
                  onClick={handleSaveVacancy}
                  style={{ fontSize: "0.9rem", fontWeight: 500 }}
                >
                  Сохранить
                </Button>
              </Layout>
            </HeaderControl>
          }
        >
          <MappingWrapper>
            <Dnd
              dataSource={filteredTools}
              values={values}
              onDrop={handleDrop}
              onReset={handleReset}
              onAppend={handleAppend}
              onRemove={handleRemove}
              onDoubleClick={handleSetPriority}
              parentVacancyName={positionName}
            />
          </MappingWrapper>
        </DnDContentWrapper>
      </Layout>
    </ContentWrapper>
  );
};

export default VacancyMapping;
