import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import PositionsAPI from "../../api/fpositions.api";

export type ToolType = "task" | "instrument" | "position";

export interface ITool {
  id: string;
  origin_id: string;
  type: ToolType;
  // percentage?: number;
  // priority?: boolean;
  is_disabled?: boolean;
  name: string;
  is_priority: boolean;
}

export interface IPosition {
  id: string;
  name: string;
  comment?: string | null;
  origin_id?: string | null;
  owner_id?: string | null;
  tools: ITool[];
}

const initialState: IPosition[] = [];

export const fetchPositions = createAsyncThunk("positions/fetch", async () => {
  try {
    const positions = await PositionsAPI.fetch();
    // патчим tools данными для Transfer
    console.log("positions", positions);
    const patchedPositions = positions.map((position) => {
      position.tools.forEach((tool) => (tool.type = "instrument"));
      return position;
    });
    console.log("mapping");
    return patchedPositions;
  } catch (error) {}
});

const positionsSlice = createSlice({
  name: "fpositions",
  initialState,
  reducers: {
    setPositions(_, { payload }: PayloadAction<IPosition[]>) {
      return payload;
    },
    updatePosition(state, { payload }: PayloadAction<IPosition>) {
      return state.map((item) => (item.id === payload.id ? payload : item));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPositions.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer: positionsReducer } = positionsSlice;

export const { setPositions, updatePosition } = actions;

export default positionsReducer;
