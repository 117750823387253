import { DragSourceMonitor, useDrag } from "react-dnd";
import { ITool } from "../../store/positions/positions.reducer";
import NewEntityTag from "../NewEntityTag";

interface TransferSourceItemProps extends ITool {
  color: string;
}

const TransferSourceItem: React.FC<TransferSourceItemProps> = (props) => {
  const [{ opacity }, drag] = useDrag(() => ({
    type: props.type,
    item: props,
    collect: (monitor: DragSourceMonitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));
  return <NewEntityTag ref={drag} {...props} opacity={opacity} />;
};

export default TransferSourceItem;
