import React from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import TransferSource from "./DndSource";
import TransferTarget from "./DndTarget";
import { ITool } from "../../store/positions/positions.reducer";

type TransferProps = {
  dataSource: ITool[];
  values: ITool[];
  onDrop: (item: ITool) => void;
  onRemove: (id: string) => void;
  onReset?: () => void;
  onAppend?: (entites: ITool[]) => void;
  onDoubleClick?: (id: string) => void;
  parentVacancyName?: string; // для key
  rowId?: keyof ITool;
};

const TransferWrapper = styled.div`
  display: flex;
  height: 100%;
  :first-child {
    gap: 1rem;
  }

  & > div:not(:nth-of-type(2)) {
    flex-basis: 100%;
  }
`;

const Transfer: React.FC<TransferProps> = ({
  dataSource,
  values,
  onDrop,
  onReset,
  onAppend,
  onRemove,
  onDoubleClick,
  rowId = "id",
  parentVacancyName,
}) => {
  return (
    <TransferWrapper>
      <DndProvider backend={HTML5Backend}>
        <TransferSource
          rowId={rowId}
          dataSource={dataSource}
          onAppend={onAppend}
          parentVacancyName={parentVacancyName}
        />
        <TransferTarget
          values={values}
          onDrop={onDrop}
          onRemove={onRemove}
          onReset={onReset}
          onDoubleClick={onDoubleClick}
          rowId={rowId}
        />
      </DndProvider>
    </TransferWrapper>
  );
};

export default React.memo(Transfer);
