import React from "react";
import styled from "styled-components";
import { ITool } from "../store/positions/positions.reducer";

import { ReactComponent as CloseIcon } from "../assets/close.svg";
import checkIcon from "../assets/check.svg";

interface EntityTagProps extends ITool {
  color: string;
  opacity?: number;
  onRemove?: (id: string) => void;
  onDoubleClick?: (id: string) => void;
}

const Tag = styled.div<Pick<EntityTagProps, "color" | "is_priority" | "opacity">>`
  display: inline-block;
  overflow: hidden;
  border: 1px solid ${({ color }) => color};
  background-color: ${({ is_priority, color }) => (is_priority ? color : "transparent")};
  // background: #f0f2f5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin-right: 8px;
  padding: 6px 8px;
  opacity: ${({ opacity = 1 }) => opacity};
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TagName = styled.div<{ is_priority?: boolean; color: string }>`
  color: ${({ is_priority, color }) => (is_priority ? "#fff" : color)};
`;
const TagPercent = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  color: #fff;
  padding: 0 5px;
  border-radius: 2px;
  margin-left: 8px;
`;

const TagBtnRemove = styled.button`
  background: transparent;
  border: 0;
  height: 19px;
  width: 19px;
  margin-left: 2px;
  cursor: pointer;
`;

const TagCheckbox = styled.div<{ is_priority?: boolean; color: string }>`
  border: 1px solid ${({ color, is_priority }) => (is_priority ? "#fff" : color)};
  border-radius: 4px;
  width: 19px;
  height: 19px;
  min-width: 19px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
  &::after {
    display: ${({ is_priority }) => (is_priority ? "block" : "none")};
    content: "";
    background-image: url(${checkIcon});
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const EntityTag = React.forwardRef<HTMLDivElement, EntityTagProps>((props, ref) => {
  const handleRemove = () => {
    if (!props.onRemove) return;
    props.onRemove(props.id);
  };

  const handleChangePriority = () => {
    if (!props.onDoubleClick) return;
    props.onDoubleClick(props.id);
  };

  // if (props.is_disabled) {
  //   return (
  //     <DisabledTag
  //       ref={ref}
  //       color={props.color}
  //       onClick={handleRemove}
  //       title="Нажмите чтобы восстановить"
  //     >
  //       <TagWrapper>
  //         <TagName color="#dae1f2">{props.name}</TagName>
  //       </TagWrapper>
  //     </DisabledTag>
  //   );
  // }

  return (
    <Tag ref={ref} color={props.color} is_priority={props.is_priority} opacity={props.opacity}>
      <TagWrapper>
        {props.onDoubleClick && (
          <TagCheckbox
            role="checkbox"
            color={props.color}
            is_priority={props.is_priority}
            onClick={handleChangePriority}
          />
        )}
        <TagName color={props.color} is_priority={props.is_priority}>
          {props.name}
        </TagName>
        {/* {props.onRemove ? (
          <TagBtnRemove title="Удалить" onClick={handleRemove}>
            <CloseIcon stroke={props.is_priority ? "#fff" : props.color} />
          </TagBtnRemove>
        ) : (
          props.percentage && (
            <TagPercent color={props.color}>{Math.round(props.percentage)}%</TagPercent>
          )
        )} */}
        {props.onRemove && (
          <TagBtnRemove title="Удалить" onClick={handleRemove}>
            <CloseIcon stroke={props.is_priority ? "#fff" : props.color} />
          </TagBtnRemove>
        )}
      </TagWrapper>
    </Tag>
  );
});

export default EntityTag;
