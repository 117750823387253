import { LeftOutlined } from "@ant-design/icons";
import { Col, PageHeader, Row } from "antd";
import React from "react";
import styled from "styled-components";

type ContentWrapperProps = {
  title?: React.ReactNode;
  icon: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  extra?: React.ReactNode;
  onBack?: () => void;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  style?: React.CSSProperties;
};

const StyledRow = styled(Row)`
  width: 100%;
  background: #f7faff;
  padding: 0;
`;

const DnDContentWrapper: React.FC<ContentWrapperProps> = ({
  title,
  icon,
  extra,
  isLoading,
  onBack,
  className,
  headerContent,
  footerContent,
  children,
}) => {
  return (
    <StyledRow className="dnd-content">
      <Col span={30} offset={0} className={`content__body ${className}`}>
        <PageHeader
          className="content__body-title"
          title={title}
          extra={extra}
          onBack={onBack}
          backIcon={icon ? icon : <LeftOutlined />}
          footer={headerContent}
        />
        {children}
      </Col>
      <Col span={22} offset={1} className={`content__footer ${className}`} style={{ margin: 0 }}>
        {footerContent}
      </Col>
    </StyledRow>
  );
};

export default DnDContentWrapper;
