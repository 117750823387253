import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ITool } from "./positions.reducer";

export const selectPositions = (state: RootState) => state.fpositions;

export const selectAllTools = createSelector(selectPositions, (state) => {
  const tools = state.map((position) => position.tools);
  // уплощим массив
  const flatTools: ITool[] = ([] as ITool[]).concat(...tools);
  // создадим массив уникальных tools
  const uniqueTools = [...new Set(flatTools)];
  return uniqueTools;
});
