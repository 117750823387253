import { useEffect, useState } from "react";
import Input from "antd/lib/input";

import { typeColor } from "../../helpers/typeColor";
import { ScrolledBlock, WhiteAroundBlock } from "../../shared/block.styled";
import { TransferButton, BoxTitle } from "./dnd.styled";
import TransferSourceItem from "./DndSourceItem";
import { ITool } from "../../store/positions/positions.reducer";

import { Layout } from "antd";

type TransferSourceProps = {
  dataSource: ITool[];
  onAppend?: (tools: ITool[]) => void;
  parentVacancyName?: string; // для key
  rowId: keyof ITool;
};

const TransferSource: React.FC<TransferSourceProps> = ({
  dataSource,
  onAppend,
  rowId,
  parentVacancyName,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const filteredData = dataSource.filter((item) =>
    // console.log("searchValue", searchValue);
    searchValue ? item.name.toLowerCase().includes(searchValue.toLowerCase()) : []
  );

  useEffect(() => {
    console.log("filteredData", filteredData);
  }, [filteredData]);

  return (
    <WhiteAroundBlock
      style={{
        maxWidth: "50%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BoxTitle level={5}>Доступные инструменты</BoxTitle>
      <Input.Search
        size="middle"
        placeholder="Поиск по инструментам"
        style={{ marginBottom: "1.3rem" }}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Layout
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <ScrolledBlock height={455}>
          {filteredData.map((item) => {
            // console.log("KEY:", `${item[rowId]?.toString()}-${parentVacancyName}`);
            return (
              <TransferSourceItem
                key={`${item[rowId]?.toString()}-${parentVacancyName}`}
                // color={typeColor[item.type]}
                color="#a1a0a2"
                {...item}
              />
            );
          })}
        </ScrolledBlock>
        {onAppend && (
          <TransferButton onClick={() => onAppend(filteredData)}>Добавить все</TransferButton>
        )}
      </Layout>
    </WhiteAroundBlock>
  );
};

export default TransferSource;
