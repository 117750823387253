import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, configureStore, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";

import appReducer from "./app.reducer";
import authReducer from "./auth.reducer";
import vaccanciesReducer from "./vacancies.reducer";
import usersReduces from "./users.reducer";
import entitiesReducer from "./entities.reducer";
import fpositionsReducer from "./positions/positions.reducer";
import positionsReducer from "./positions.reducer";
import resumesReducer from "./resumes.reducer";
import questionsReducer from "./questions.reducer";
import tasksReducer from "./tasks.reducer";

export const middleware = (getDefaultMiddleware: any) => {
  return getDefaultMiddleware({ immutableCheck: true, serializableCheck: true, thunk: true });
};

export const reducer = {
  auth: authReducer,
  vacancies: vaccanciesReducer,
  users: usersReduces,
  app: appReducer,
  entities: entitiesReducer,
  positions: positionsReducer,
  fpositions: fpositionsReducer,
  resumes: resumesReducer,
  questions: questionsReducer,
  tasks: tasksReducer,
};

export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
